import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Layout from "../components/Layout"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import IGLOO_DATA from "../data/iglooData"
import SideBySide from "../components/SideBySide"
import { Box, Heading, Text, Link, Image } from "theme-ui"
import { theme } from "../globalStyles"
import Countdown from "../components/widgets/Countdown"
import IglooForm from "../components/forms/IglooForm.js"
import useStories from "../hooks/useStories"
import iglooData from "../data/iglooData"
import MultipleLinksSelector from "../components/Links/MultipleLinksSelector"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// ? =================
// ? === Functions ===
// ? =================

const renderIglooData = igloos =>
  igloos.map((igloo, idx) => (
    <Box className="igloo-card">
      <SideBySide data={igloo} idx={idx} />
    </Box>
  ))

// ? =========================
// ? === Default Component ===
// ? =========================

export default function OctoberFest() {
  const { getStoriesByTag, getStoryByTag } = useStories()

  const iglooIntro = getStoryByTag("igloo-intro")
  const iglooIntro2 = getStoryByTag("igloo-intro2")
  const igloos = getStoriesByTag("igloo")

  return (
    <Layout pageTitle="Welcome to dockside igloo park">
      <Page>
        {/* <Countdown /> */}
        {/* <h1
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "center",
            backgroundColor: "#ca3625",
            padding: "1rem",
            color: "white",
            fontSize: "2rem",
          }}
        >
          {" "}
          Returning fall 2022
        </h1> */}
        {/* <FormContainer>
          <p>Suggest a theme for next season!</p>
          <IglooForm formName="Igloo Form" />
        </FormContainer> */}

        <AboutContent>
          <AboutText>
            <AboutDescription>
              <AboutTitle>{iglooIntro?.title}</AboutTitle>
              {iglooIntro?.body && (
                <div dangerouslySetInnerHTML={{ __html: iglooIntro?.body }} />
              )}
              <br></br>
              <MultipleLinksSelector links={iglooIntro?.ctaLinks} />
            </AboutDescription>
          </AboutText>

          <SliderContainer>
            <Slider {...settings}>
              {iglooIntro.media.map(image => {
                return (
                  <Image
                    sx={{ height: "100%", width: "100%", objectFit: "cover" }}
                    src={cloudinaryOptimize(
                      `https://res.cloudinary.com/gonation/${image.cloudinaryId}`,
                      1200
                    )}
                  />
                )
              })}
            </Slider>
          </SliderContainer>
        </AboutContent>

        <div
          style={{
            backgroundColor: "#01567D",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: ["2rem 2rem"],
              maxWidth: ["90%", "90%", "50%"],
              backgroundColor: "primary",
            }}
          >
            <Heading
              as="h2"
              sx={{
                fontSize: ["1.75rem", "2rem", "2.5rem"],
                color: "secondary",
              }}
            >
              {iglooIntro2?.title}
            </Heading>
            <Box sx={{ color: "white" }}>
              {iglooIntro?.body && (
                <div dangerouslySetInnerHTML={{ __html: iglooIntro2?.body }} />
              )}
            </Box>
          </Box>
        </div>

        {renderIglooData(igloos)}
      </Page>
    </Layout>
  )
}

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  interval: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Page = styled.section``

const AboutContent = styled.section`
  padding: 2rem 0;
  background-color: ${props => (props.theme.primary ? "white" : "black")};
  width: 100%;
  ul,
  li {
    list-style: none;
  }
  @media (min-width: 1024px) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`
const AboutText = styled.div`
  color: #01567d;
  padding: 1rem;
  @media (min-width: 1024px) {
    padding-right: 4rem;
    width: 50%;
    padding: 0;
    max-width: 700px;
  }
`

const AboutTitle = styled.h1`
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.75rem;
  text-transform: uppercase;
`

const AboutDescription = styled.div`
  padding: 1.5rem 2rem;
  ul,
  li {
    list-style: disc;
  }
  li {
    margin-bottom: 1.5rem;
    line-height: 1.75;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }
  p {
    span {
      font-weight: bold;
      color: ${({ theme }) => theme.secondary};
    }
  }
  a {
    color: white;
  }
  .button {
    background: ${({ theme }) => theme.secondary};
    border: none;
    padding: 1.5rem 2.25rem;
    border: ${({ theme }) => `2px solid ${theme.secondary}`};
    text-transform: uppercase;
    &:hover {
      background: transparent;
      color: white;
      transition: all 0.3s;
    }
  }
`

const SliderContainer = styled.div`
  display: block;
  .slick-slider {
    height: 70vw;
  }
  .slick-slide,
  .slick-slide > div,
  .slick-list,
  .slick-track {
    height: 100%;
    width: 100%;
  }

  .slick-dots {
    bottom: 1.5rem;
  }
  .slick-dots li.slick-active button:before {
    color: white;
    font-size: 1.25rem;
  }

  .slick-dots li button:before {
    color: ${({ theme }) => theme.primary};
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    width: 50%;
    max-width: 700px;
    .slick-slider {
      height: 40vw;
    }
  }
`
const AboutImage = styled.img`
  width: 100%;
  max-height: 80vh;
`

const Important = styled.h2`
  color: ${({ theme }) => theme.secondary};
  margin: 1rem 0;
`

const FormContainer = styled.section`
  padding: 2rem 1.5rem;
  text-align: center;
  p {
    margin-bottom: 1rem;
  }
`

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
