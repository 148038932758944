import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { Flex, Box, Text, Image } from "theme-ui"

import { theme } from "../globalStyles"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import MultipleLinksSelector from "./Links/MultipleLinksSelector"
import TextContent from "./TextContent"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SideBySide = ({ data, idx }) => {
  const { title, subtitle, body, media, ctaLinks } = data

  return (
    <Flex
      sx={{
        flexDirection: ["column", "column", "row"],
        padding: ["1rem", "1rem", "0rem"],
      }}
    >
      <Flex
        sx={{
          width: ["100%", "100%", "100%"],
          justifyContent: "flex-start",
          alignItems: "center",
          order: [1, "", [0, idx % 2 === 0 ? 1 : 0]],
          background: idx % 2 === 0 ? theme.primary : "white",
          boxShadow: "2px 2px 20px rgba(0, 0, 0, .4)",
        }}
      >
        <Box
          sx={{
            padding: 3,
            paddingX: [3, 4],
            minHeight: ["200px", "", "", ""],
            color: idx % 2 === 0 ? "white" : "black",
          }}
        >
          <TextContent title={title} subtitle={subtitle} body={body} />
          <MultipleLinksSelector links={ctaLinks} />
        </Box>
      </Flex>
      <Box sx={{ width: ["100%", "100%", "100%"] }}>
        <SliderContainer>
          <Slider {...settings}>
            {media.map(image => {
              return (
                <Image
                  sx={{ height: "100%", width: "100%", objectFit: "cover" }}
                  src={cloudinaryOptimize(
                    `https://res.cloudinary.com/gonation/${image.cloudinaryId}`,
                    1200
                  )}
                />
              )
            })}
          </Slider>
        </SliderContainer>
      </Box>
    </Flex>
  )
}

export default SideBySide

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  interval: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const SliderContainer = styled.div`
  display: block;
  .slick-slider {
    height: 70vw;
  }
  .slick-slide,
  .slick-slide > div,
  .slick-list,
  .slick-track {
    height: 100%;
    width: 100%;
  }

  .slick-dots {
    bottom: 1.5rem;
  }
  .slick-dots li.slick-active button:before {
    color: white;
    font-size: 1.25rem;
  }

  .slick-dots li button:before {
    color: ${({ theme }) => theme.primary};
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    .slick-slider {
      height: 40vw;
    }
  }
`
